<template>
  <div class="head-container performance-header">
    <!-- 搜索 -->
    <el-input
      v-model="query.custInfo"
      placeholder="客户姓名/ID/MT4"
      clearable
      style="width: 150px;"
      class="filter-item"
    ></el-input>
    <el-input
      v-model="query.managerInfo"
      placeholder="销售姓名/ID"
      clearable
      class="filter-item"
      style="width: 150px;"
    ></el-input>
    <el-input
      v-model="query.ibInfo"
      clearable
      placeholder="代理姓名/ID"
      class="filter-item"
      style="width: 150px;"
      @keyup.enter.native="toQuery">
    </el-input>
    <el-input
      v-model="query.symbol"
      placeholder="交易品种"
      clearable
      class="filter-item"
      style="width: 150px;"
    ></el-input>
     <div class="filter-item">
       <!--接口必须传时间, 选择时间-->
      <span class="filter-item">平仓时间</span>
      <el-date-picker
        style="width: 150px;"
        v-model="startTime"
        type="date"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        placeholder="开始时间">
      </el-date-picker>
      至
      <el-date-picker
        style="width: 150px;"
        v-model="endTime"
        type="date"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        placeholder="结束时间">
      </el-date-picker>
    </div>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button class="filter-item" icon="el-icon-close" type="primary" size="mini" @click="clear">清空</el-button>
    <el-button
      v-if="checkPermission(['EXPORT_TRADE_HISTORY','ADMIN'])"
      class="filter-item"
      size="mini"
      type="primary"
      :loading="btnLoading"
      @click="exportHandle"
    >导出</el-button>
  </div>
</template>

<script>
  import { init_date } from '@/utils/index'
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  created(){
    this.initDate();
  },
  data() {
    return {
      startTime: '',
      endTime: '',
      btnLoading: false,
    }
  },
  methods: {
    checkPermission,
    initDate(){
      let dateInfo = init_date();
      this.endTime = dateInfo.today;
      this.startTime = dateInfo.firstday;
    },
    toQuery() {
      this.$emit('toQuery');
    },
    clear() {
      this.$emit('clear');
    },
    exportHandle(){
      this.$emit('export');
    },
  }
}
</script>
<style lang="less">
  .performance-header {
    .el-input-group__append {
      background-color: #ffffff;
    }
  }
</style>
