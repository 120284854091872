<template>
  <div class="app-container summary-page trading-record">
    <cent-tab
        ref="centtab"
        @send-type="sendType"
    ></cent-tab>
    <eHeader
      ref='header'
      :query="query"
      @export="exportExecl"
      @clear="clearHandle"
      @toQuery="queryHandle"
    />
    <blockquote class="my-blockquote statistics-box">
      <div class="statistics-item">
        总手数:{{statistics.totalVolume}}手
        <el-divider direction="vertical" class="statistics-item-divider"></el-divider>
      </div>
      <div class="statistics-item">
        Forex: {{statistics.volumeMap.Forex}}手
        <el-divider direction="vertical" class="statistics-item-divider"></el-divider>
      </div>
      <div class="statistics-item">
        Gold: {{statistics.volumeMap.GOLD}}手
        <el-divider direction="vertical" class="statistics-item-divider"></el-divider>
      </div>
      <div class="statistics-item">
        siver: {{statistics.volumeMap.SIVLER}}手
        <el-divider direction="vertical" class="statistics-item-divider"></el-divider>
      </div>
      <div class="statistics-item">
        Oil: {{statistics.volumeMap.Oil}}手
        <el-divider direction="vertical" class="statistics-item-divider"></el-divider>
      </div>
      <div class="statistics-item">
        Index: {{statistics.volumeMap.INDEX}}手
      </div>
<!--      把Stock统计上,单位有待联调 todo-->
      <div class="statistics-item">
        Stock: {{statistics.volumeMap.Stock}}手
      </div>
    </blockquote>
    <div class="table-outer">
      <div class="client-tree">
        <div v-if="treeData.length" class="result">搜索结果</div>
        <client-tree
          v-if="treeData.length"
          :tree-data="treeData"
          @node-click="handleNodeClick"
        ></client-tree>
      </div>
      <el-table
        v-loading="loading"
        :data="data"
        stripe
        size="small"
        style="width: 100%;"
      >
        <el-table-column prop="rank" label="序号" width="50"/>
        <el-table-column prop="orderId" label="订单号"/>
        <el-table-column label="客户信息">
          <template slot-scope="scope">
            <div>{{scope.row.custName}}</div>
            <div>{{scope.row.custId}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="login" label="MT账户">
          <template slot-scope="scope">
            <div>{{scope.row.custLogin}}</div>
            <!--增加服务器字段-->
            <div>{{scope.row.group}}</div>
          </template>
        </el-table-column>
        <el-table-column label="代理">
          <template slot-scope="scope">
            <div>{{scope.row.belongName || '-'}}</div>
            <span>{{scope.row.belongId}}</span>
          </template>
        </el-table-column>
        <el-table-column label="销售">
          <template slot-scope="scope">
            <div>{{scope.row.managerName}}</div>
            <span>{{scope.row.managerId}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="symbol" label="交易产品"/>
        <el-table-column prop="cmd" label="交易类型">
          <template slot-scope="scope">
            <span>{{scope.row.cmd == 0 ? '买' : '卖'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="volume" label="手数"/>
        <el-table-column prop="openPrice" label="开仓价格"/>
        <el-table-column prop="openTime" label="开仓时间" >
          <template slot-scope="scope">
            <span>{{ parseTime(scope.row.openTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="closePrice" label="平仓价格"/>
        <el-table-column prop="closeTime" label="平仓时间" >
          <template slot-scope="scope">
            <span>{{ parseTime(scope.row.closeTime) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import clientTree from '@/components/public/clientTree'
import checkPermission from '@/utils/permission'
import initDataComm from '@/mixins/initData'
import { initData } from '@/api/data'
import { parseTime } from '@/utils/index'
import { export_json_to_excel,formatJson } from '@/vendor/Export2Excel'
import eHeader from '@/components/customer/tradingRecord/header'
export default {
  name:'tradingRecord',
  components: {
    eHeader,
    clientTree,
  },
  mixins: [initDataComm],
  data() {
    return {
      loading: false,
      statistics:{
        totalVolume: '',
        totalStorage: '',
        totalCommission: '',
        totalProfit: '',
        volumeMap: {
          Forex: '',
          GOLD: '',
          SIVLER: '',
          Oil: '',
          INDEX: '',
        }
      },
      treeData: [],
      export: 0,
      isTree: 1,
      treeCurrentNodeId: 0,
      ibRank: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    export_json_to_excel,
    formatJson,
    initData,
    sendType(type){
      this.init();
    },
    beforeInit() {
      this.url = 'crm/queryCustStatisticsOrder';
      this.params = {
        page: this.page,
        size: this.size,
        export: this.export,
        isTree: this.isTree,
        currency: this.$refs.centtab.type,
      }
      const query = this.query;
      const symbol = query.symbol;
      if (symbol!=null && symbol!='') { this.params['symbol'] = symbol }
      if(this.isTree){
        if (this.query.ibInfo) {
          this.params.ibInfo = this.query.ibInfo;
        }
      }else {
        if(this.ibRank === 0){
          this.params.custInfo = this.treeCurrentNodeId;
        }else {
          this.params.ibInfo = this.treeCurrentNodeId;
        }
      }

      if((this.$refs.header.startTime)){
        this.params.startTime = this.parseTime(this.$refs.header.startTime);
      }
      if((this.$refs.header.endTime)){
        this.params.endTime = this.parseTime(this.$refs.header.endTime);
      }
      if(this.query.custInfo){
        this.params.custInfo = this.query.custInfo;
      }
      if(this.query.managerInfo){
        this.params.managerInfo = this.query.managerInfo;
      }
      return true
    },
    exportExecl(){
      let params = {
        export: true,
        isTree: 0,
      }
      if (this.query.symbol) {
        params.symbol = this.query.symbol;
      }

      if (this.query.ibInfo) {
        params.ibInfo = this.query.ibInfo;
      }

      if((this.$refs.header.startTime)){
        params.startTime = this.parseTime(this.$refs.header.startTime);
      }
      if((this.$refs.header.endTime)){
        params.endTime = this.parseTime(this.$refs.header.endTime);
      }
      if(this.query.custInfo){
        params.custInfo = this.query.custInfo;
      }
      if(this.query.managerInfo){
        params.managerInfo = this.query.managerInfo;
      }

      this.$refs['header'].btnLoading = true;
      initData(this.url, params).then(res => {
        this.$refs['header'].btnLoading =false;
        //todo
        for (let i = 0; i < res.content.length; i++) {
          res.content[i].tradeType = res.content[i].cmd == 0 ? '买' : '卖';
          res.content[i].openTimeSelf = parseTime(res.content[i].openTime);
          res.content[i].closeTimeSelf = parseTime(res.content[i].closeTime);
        }
        const tHeader =['序号','订单号','MT账户','代理名字','销售姓名','交易产品', '交易类型','手数','开仓价格','开仓时间','平仓价格','平仓时间',];
        const filterVal =['rank','orderId','custLogin','belongName','managerName','symbol','tradeType','volume','openPrice','openTimeSelf','closePrice','closeTimeSelf'];
        const exportData = formatJson(filterVal,res.content);
        let fileName='交易记录';
        export_json_to_excel(tHeader,exportData,fileName);
      }).catch(err => {
        this.$refs['header'].btnLoading =false;
      })
    },
    queryHandle(){
      this.page = 0;
      this.init();
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        switch (index) {
          case 0:
            sums[index] = '合计';
            break;
          case 10:
            sums[index] = `${this.statistics.totalStorage}`;
            break;
          case 11:
            sums[index] = `${this.statistics.totalCommission}`;
            break;
          case 12:
            sums[index] = `${this.statistics.totalProfit}`;
            break;
        }
      });

      return sums;
    },
    handleNodeClick(data, isRoot) {
      this.isTree = 0;
      this.page = 0;
      this.treeCurrentNodeId = data.id;
      this.ibRank = data.ibRank;
      this.init().then(() => {
        this.isTree = 1;
      });
    },
    clearHandle(){
      this.query = {
        custInfo: '',
        managerInfo: '',
        ibInfo: '',
        symbol: '',
      }
    }
  }
}
</script>

<style lang="less">
  .trading-record {
    .el-table {
          .el-table__footer-wrapper {
              .has-gutter {
                  tr {
                      .is-leaf:nth-of-type(6) {
                          .cell {
                              white-space: pre-wrap !important;
                          }
                      }
                  }
              }
          }
      }
    .result {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
</style>
